h1.heading {
  font-size: 32px;
}

h2.heading {
  font-size: 28px;
}

h3.heading {
  font-size: 24px;
}

h4.heading {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  h1.heading {
    font-size: 28px;
  }

  h2.heading {
    font-size: 24px;
  }

  h3.heading {
    font-size: 20px;
  }

  h4.heading {
    font-size: 16px;
  }
}