.bgc-lightGray {
  background-color: #f7f7f7;
}

.bgc-lightBlue {
  background-color: #d9f2fc;
}

.bgc-white {
  background-color: #ffffff;
}

.bg-overflow-x {
  background-color: #f8f8f8;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
}

.bg-overflow-x .inner-overflow-x {
  width: 320px;
  margin: 0 auto;
  /* HACK: 既存のinner-contentsのスタイルに合わせるためやむなく。 */
  @media screen and (min-width: 374px) {
    max-width: 700px;
    width: 100%;
    padding: 0 16px;
  }
  @media screen and (min-width: 774px) {
    padding: 0;
  }
}