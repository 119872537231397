/* mixins ----------------------------- */
.noneAppearance {
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	background: none transparent;
	color: inherit;
	font-size: inherit;
	vertical-align: middle;
	-webkit-appearance: none;
	appearance: none;
	box-shadow: none;
	box-sizing: border-box;

	-moz-appearance: none;
}
.noneAppearance::-ms-expand {
	display: none;
}
.noneAppearance::-ms-clear {
	display: none;
}
.noneAppearance::-ms-reveal {
	display: none;
}
.clearFix:after {
	content: '.';
	display: block;
	visibility: hidden;
	clear: both;
	height: 0;
}
/* customized ------------------------- */
html, body {
	height: 100%;
	min-height: 100%;
}
body {
	font-family: 'HelveticaNeue-Light', 'Yu Gothic Medium', YuGothic, '游ゴシック Medium', 'ヒラギノ角ゴPro W3', 'Hiragino Kaku Gothic Pro', Meiryo, 'メイリオ', Helvetica, Arial, sans-serif;
	overflow-y: scroll;
}
label span {
	max-height: 99999px;
	background: transparent;
}
p {
	font-size: 1.2rem;
}
.number-value {
	font-family: 'HelveticaNeue-Thin', YuGothic, '游ゴシック', 'ヒラギノ角ゴPro W3', 'Hiragino Kaku Gothic Pro', Meiryo, 'メイリオ', Helvetica, Arial, sans-serif;
}
.wrapper {
	display: table;
	width: 100%;
	height: 100%;
	table-layout: fixed;

	overflow-y: scroll;
}
.wrapper > .navbar, .wrapper > .container, .wrapper > footer {
	display: table-row;
	background: #FFF;
}

// GoalInvestmentPageのみ左右padding無し
.goal-body {
	.wrapper>.container {
		padding: 0 !important;
	}

	.wrapper>.container>.inner .inner-contents {
		padding: 0 0 50px !important;
	}
}

.wrapper > .navbar:before, .wrapper > .container:before, .wrapper > footer:before, .wrapper > .navbar:after, .wrapper > .container:after, .wrapper > footer:after {
	display: none;
}
.wrapper > .navbar > .inner, .wrapper > .container > .inner, .wrapper > footer > .inner {
	display: table-cell;
}
.wrapper > .navbar > .inner .inner-contents, .wrapper > .container > .inner .inner-contents, .wrapper > footer > .inner .inner-contents {
	position: relative;
}
/* header */
.wrapper > .navbar {
	height: 40px;
	min-height: 40px;
}
.wrapper > .navbar > .inner .inner-contents {
	width: 100%;
	height: 40px;
	min-height: 40px;
	margin: 0;
	border: 0;
	border-radius: 0;
}
.wrapper > .navbar > .inner .inner-contents .navbar-header {
	position: relative;
	margin: 0;
}
.wrapper .navbar-brand {
	overflow: hidden;
	width: 62px;
	height: 20px;
	margin: 10px;
	padding: 0;
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHkAAAAlCAYAAABmtkbFAAACjElEQVR4Ae3cA4xdXRRA4fPbtm2ztm3bRlgjnAlqhrVtu2Ft27axu+r25L43c9nBTvINn9e1zPM19i3H6RRiDz6E8aAnVltKwThIwOkINIRJwq9oibFYh7OQey5hD+YgEfnxHMx9IpIkww0lhfkTxoPdEEt3GAcLISHbjE9hHLyEplgLcek4euGrJxtZI2/BezCWp1EPRyA+XUd/vBN9ZI18At/BWD7DEkjAjqNYUpEXY7cLVyAOzmG3CxdSYOQD2O3DVmSGsWTFSUiIOuOpWJHdqgdx8B+MC/lSWOSleAomYIVwKRlj40DUQiZ8jS/xJ0ohMZnz7354KojIJSEOvoRx4c8UFnkiTMAy4SIkho2oiOdgkuFvjILEkRBdZI38Pg5CHFxFazwL40E27ILEUDaayBp5cpwFs8wwPr0ZZ9niDD4KN7JGLhHnw/8dJiAvYj7EwcjwImvkp7AK4qAQTMDeijPp/jmcyBq5IMRBX5igCRH5nh3iYFA4kTXy6BiT6TfDiiyIsdR9Aa8FG1kjP4/zEEtXmJAj/wtxUDrYyBo5C8TBH2FHvhd6I8TSOyVGboc/XcqBcz4jb8Y/+NOFl6zHbQqxHIaJKHJ3iGVJCoocgvB3UPRw2KctlukRRq4OsRzVyP7YS8yDIZb+EUbOBbFc18jBPv5Ep9tEGPlviE0j+9NDx2RvkddhoEvTAoh8A8Mx0IWcOk/2FvkfGA/2+Yw8BcYTXbrW9WRdT9YtXrrFS7dd67Zr3Qule6F0f7JG1iNDNLIe46WR9WhNjazHXYcYWc+g0Mjhngu1A9lTwLlQaTmyntWYliPr+ckaWa804NaPOIjTj9iEN2Bc+BB7ArxmyFictlTWa4aIuQUenz94Ax+CAQAAAABJRU5ErkJggg==") no-repeat 0 0;
	background-size: 62px 20px;
	text-indent: -200%;
}
.wrapper .navbar-brand.kitakyushubank {
	background: url(/_/image/img_logo_plus_kitakyushubank.png) no-repeat 0 0;
	background-size: 181px 19px;
	width: 181px;
}
.wrapper .navbar-brand.momijibank {
	background: url(/_/image/img_logo_plus_momijibank.png) no-repeat 0 0;
	background-size: 180px 20px;
	width: 180px;
}
.wrapper .navbar-brand.yamaguchibank {
	background: url(/_/image/img_logo_plus_yamaguchibank.png) no-repeat 0 0;
	background-size: 170px 20px;
	width: 170px;
}
.wrapper .navbar-brand.musashinobank {
	background: url(/_/image/img_logo_plus_musashinobank.png) no-repeat 0 0;
	background-size: 181px 19px;
	width: 181px;
}
.wrapper .navbar-brand.sbisec {
	background: url(/_/image/img_logo_plus_sbisec.png) no-repeat 0 0;
	background-size: 160px 19px;
	width: 160px;
}
.wrapper .navbar-brand.ssnb {
	background: url(/_/image/img_logo_plus_ssnb.png) no-repeat 0 0;
	background-size: 180px 13px;
	width: 180px;
}
.wrapper .navbar-brand.fukuokabank {
	background: url(/_/image/img_logo_plus_fukuokabank.png) no-repeat 0 0;
	background-size: 173px 20px;
	width: 173px;
}
.wrapper .navbar-brand.kumamotobank {
	background: url(/_/image/img_logo_plus_kumamotobank.png) no-repeat 0 0;
	background-size: 171px 20px;
	width: 171px;
}
.wrapper [class="navbar-brand 18shinwabank"] {
	background: url(/_/image/img_logo_plus_18shinwabank.png) no-repeat 0 0;
	background-size: 180px 18px;
	width: 180px;
}
.wrapper .navbar-brand.okb {
	background: url(/_/image/img_logo_plus_okb.png) no-repeat 0 0;
	background-size: 136px 20px;
	width: 136px;
}
.wrapper .navbar-brand.shinseibank {
	background: url(/_/image/img_logo_plus_shinseibank.png) no-repeat 0 0;
	background-size: contain;
	width: 184px;
}
.wrapper .navbar-brand.jal {
	background: url(/_/image/img_logo_plus_jal.png) no-repeat 0 0;
	background-size: 147px 30px;
	width: 147px;
	height: 30px;
	margin: 5px;
}
.wrapper .navbar-brand.asahi-shinkin {
	background: url(/_/image/img_logo_plus_asahi-shinkin.png) no-repeat 0 0;
	background-size: 180px 16px;
	width: 180px;
}
.wrapper .navbar-brand.okinawa-bank {
	background: url(/_/image/img_logo_plus_okinawa-bank.png) no-repeat 0 0;
	background-size: 175px 20px;
	width: 175px;
}
.wrapper .navbar-brand.tsukubabank {
	background: url(/_/image/img_logo_plus_tsukubabank.png) no-repeat 0 0;
	background-size: 175px 20px;
	width: 175px;
}
.wrapper .navbar-brand.docomo {
	background: url(/_/image/img_logo_plus_docomo.png) no-repeat 0 0;
	background-size: 175px 19px;
	width: 175px;
}
.wrapper .navbar-brand.firstbank {
	background: url(/_/image/img_logo_plus_firstbank.png) no-repeat 0 0;
	background-size: 170px 20px;
	width: 170px;
}
.wrapper .navbar-brand.azn {
	background: url(/_/image/img_logo_plus_azn.png) no-repeat 0 0;
	background-size: contain;
	width: 170px;
	height: 20px;
}
.wrapper .navbar-brand.hirogin {
	background: url(/_/image/img_logo_plus_hirogin.png) no-repeat 0 0;
	background-size: contain;
	width: 170px;
	height: 20px;
}
.wrapper .navbar-brand.sbj {
	background: url(/_/image/img_logo_plus_sbj.png) no-repeat 0 0;
	background-size: contain;
	width: 175px;
	height: 20px;
}
.wrapper .navbar-brand.yamanashibank {
	background: url(/_/image/img_logo_plus_yamanashibank.png) no-repeat 0 0;
	background-size: 180px 16px;
	width: 180px;
}
.wrapper .navbar-brand.juroku {
	background: url(/_/image/img_logo_plus_juroku.png) no-repeat 0 0;
	background-size: 168px 20px;
	width: 168px;
}
.wrapper .navbar-brand.nantobank {
	background: url(/_/image/img_logo_plus_nantobank.png) no-repeat 0 0;
	background-size: 168px 20px;
	width: 168px;
}
.wrapper .navbar-brand.tokaitokyo {
	background: url(/_/image/img_logo_plus_tokaitokyo.png) no-repeat 0 0;
	background-size: contain;
	width: 184px;
}
.wrapper .navbar-brand.sagabank {
	background: url(/_/image/img_logo_plus_sagabank.png) no-repeat 0 0;
	background-size: 168px 20px;
	width: 168px;
}
.wrapper [class="navbar-brand 82bank"] {
	background: url(/_/image/img_logo_plus_82bank.png) no-repeat 0 0;
	background-size: 180px 19px;
	width: 180px;
}
.wrapper [class="navbar-brand ibank"] {
	background: url(/_/image/img_logo_plus_ibank.png) no-repeat 0 0;
	background-size: 150px 20px;
	width: 180px;
}

.login-body .navbar,
.profiling-body .navbar {
	display: none;
}

/* footer */
footer > .inner .inner-contents {
	text-align: center;
	padding: 32px 12px 10px;
	color: #A8AFB9;

	@media (min-width: 500px) {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		padding: 32px 16px 10px;
	}

	ul {
		margin: 0;
		font-size: 0;
		line-height: 0;

		li {
			font-size: 1.2rem;
			line-height: 1.3;
			display: inline-block;
			margin: 0;
			padding: 0 10px;
			a {
				color: inherit;
			}

			border-right: 1px solid #E3E3E3;
		}

		li:first-child {
			padding-left: 0;
		}

		li:last-child {
			border-right: none;
			padding-right: 0;

			@media (max-width: 359px) {
				margin-top: 16px;
				padding-left: 0;
			}
		}
	}

	footer > .inner .inner-contents ul:after {
		content: '.';
		display: block;
		visibility: hidden;
		clear: both;
		height: 0;
	}

	.copyright {
	  margin: 0;
		color: #A8AFB9;
		font-size: 1rem;
		line-height: 1;

		@media (max-width: 500px) {
			margin-top: 16px;
		}
	}
}

/* container */
.container {
	position: relative;
	font-size: 10px;
	font-size: .625rem;
}
.wrapper > .container {
	height: 100%;
}
.wrapper > .container > .inner {
	vertical-align: top;
}
.login .wrapper > .container > .inner {
	vertical-align: middle;
}
.wrapper > .container > .inner .inner-contents {
	width: 320px;
	min-height: 400px;
	padding: 0 0 50px;
}
.top .wrapper > .container > .inner .inner-contents, .asset .wrapper > .container > .inner .inner-contents {
	margin-bottom: 0;
	padding-bottom: 0;
}
.navbar .container {
	width: auto;
	padding: 0;
}
/* link-head */
.wrapper-link-head {
	text-align: right;
}
/* page-step */
.wrapper-page-step {
	display: block;
	position: relative;
	width: 100%;
	height: 22px;
	margin: 0 0 8px;
	padding: 0 0 4px;
	background: #FFF;
	color: #00263F;
	font-size: 1.6rem;
	line-height: 1;
}
.wrapper-page-step:before {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: #E6EBF2;
}
.wrapper-page-step:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 2px;
	background: #0C41C6;
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzBjNDFjNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ5JSIgc3RvcC1jb2xvcj0iIzAwN2RmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGMzZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(left, #0C41C6 0%, #007DFF 49%, #00C3FF 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%, #0C41C6), color-stop(49%, #007DFF), color-stop(100%, #00C3FF));
	background: -webkit-linear-gradient(left, #0C41C6 0%, #007DFF 49%, #00C3FF 100%);
	background: -o-linear-gradient(left, #0C41C6 0%, #007DFF 49%, #00C3FF 100%);
	background: -ms-linear-gradient(left, #0C41C6 0%, #007DFF 49%, #00C3FF 100%);
	background: linear-gradient(to right, #0C41C6 0%, #007DFF 49%, #00C3FF 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0c41c6', endColorstr='#00c3ff', GradientType=1);
}
.wrapper-page-step.step-1-6:after {
	width: 16%;
}
.wrapper-page-step.step-2-6:after {
	width: 32%;
}
.wrapper-page-step.step-3-6:after {
	width: 48%;
}
.wrapper-page-step.step-4-6:after {
	width: 64%;
}
.wrapper-page-step.step-5-6:after {
	width: 80%;
}
.wrapper-page-step.step-6-6:after {
	width: 100%;
}
.wrapper-page-step.step-1-7:after {
	width: 14%;
}
.wrapper-page-step.step-2-7:after {
	width: 28%;
}
.wrapper-page-step.step-3-7:after {
	width: 42%;
}
.wrapper-page-step.step-4-7:after {
	width: 56%;
}
.wrapper-page-step.step-5-7:after {
	width: 70%;
}
.wrapper-page-step.step-6-7:after {
	width: 85%;
}
.wrapper-page-step.step-7-7:after {
	width: 100%;
}
/* page-title */
.wrapper-page-title {
	width: 100%;
}
.welcome .wrapper-page-title {
	height: 165px;
}
.page-title {
	margin: 32px 0;
	color: #00263F;
	font-size: 1.8rem;
	line-height: 1.35;
	text-align: center;
	font-weight: bold;
}
.page-title.mod-small {
	font-size: 1.4rem;
}
.page-title.is-hidden {
	display: none;
}
/* section-title */
.section-title {
	margin: 0 0 32px;
	color: #00263F;
	font-weight: normal;
	font-size: 1.8rem !important;
	line-height: 1.4;
}
.guidance .section-title {
	margin: 0 0 24px;
}
.guidance .section-title.mod-margin {
	margin: 0 0 40px;
}
*[class^='list-o-'] > li > .section-title {
	margin: 0 0 20px;
}
.section-title a {
	padding: 0 0 3px;
	border-bottom: 1px #111 dotted;
	color: #616977;
}
.section-title a:hover {
	text-decoration: none;
}
.section-title span {
	display: inline-block;
}
.section-title.mod-middle {
	font-size: 1.6rem !important;
}
.section-title.mod-small {
	font-size: 1.2rem !important;
}
.section-title.mod-text-narrow {
	margin: 0 0 2px !important;
}
.section-title.mod-strong {
	font-weight: bold;
}
.section-title.mod-strong .mod-large {
	color: #065FE3;
	font-size: 2.3rem;
}
.section-title.mod-text-extension-head {
	margin: 50px 0 8px;
}
/* form */
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px #FFF inset !important;
}
.form-group {
	overflow: hidden;
	margin: 0 0 24px;
}
.form-group.mod-exp {
	margin: 0 0 16px !important;
}
.form-group.mod-extension {
	margin: 0 0 32px !important;
}
.form-group.mod-division {
	margin: 0 0 42px !important;
}
.form-group label {
	width: auto;
	margin-bottom: 0;
	padding: 0;
	color: #616977;
	font-weight: normal;
	font-size: 1.4rem;
}
.login .form-group {
	width: 100%;
	text-align: center;
}
.form-group input[type=text], .form-group input[type=tel], .form-group input[type=number], .form-group input[type=email], .form-group input[type=password] {
	margin: 0;
	padding: 0;
	border: 0;
	border-bottom: 1px #A2A2A2 dotted;
	border-radius: 0;
	background: none transparent;
	color: inherit;
	color: #616977;
	font-size: inherit;
	font-size: 1.8rem;
	vertical-align: middle;
	-webkit-appearance: none;
	appearance: none;
	box-shadow: none;
	box-sizing: border-box;

	-moz-appearance: none;
}
.form-group input[type=text]::-ms-expand, .form-group input[type=tel]::-ms-expand, .form-group input[type=number]::-ms-expand, .form-group input[type=email]::-ms-expand, .form-group input[type=password]::-ms-expand {
	display: none;
}
.form-group input[type=text]::-ms-clear, .form-group input[type=tel]::-ms-clear, .form-group input[type=number]::-ms-clear, .form-group input[type=email]::-ms-clear, .form-group input[type=password]::-ms-clear {
	display: none;
}
.form-group input[type=text]::-ms-reveal, .form-group input[type=tel]::-ms-reveal, .form-group input[type=number]::-ms-reveal, .form-group input[type=email]::-ms-reveal, .form-group input[type=password]::-ms-reveal {
	display: none;
}
.form-group input[type=text]:focus, .form-group input[type=tel]:focus, .form-group input[type=number]:focus, .form-group input[type=email]:focus, .form-group input[type=password]:focus {
	border-bottom: 1px #A2A2A2 solid;
	box-shadow: none;

	webkit-box-shadow: none;
}
.form-group input[type=text]::-webkit-input-placeholder, .form-group input[type=tel]::-webkit-input-placeholder, .form-group input[type=number]::-webkit-input-placeholder, .form-group input[type=email]::-webkit-input-placeholder, .form-group input[type=password]::-webkit-input-placeholder {
	color: rgba(97, 105, 119, .5);
	font-weight: normal;
}
.form-group input[type=text]:-ms-input-placeholder, .form-group input[type=tel]:-ms-input-placeholder, .form-group input[type=number]:-ms-input-placeholder, .form-group input[type=email]:-ms-input-placeholder, .form-group input[type=password]:-ms-input-placeholder {
	color: rgba(97, 105, 119, .5);
	font-weight: normal;
}
.form-group input[type=text]::-moz-placeholder, .form-group input[type=tel]::-moz-placeholder, .form-group input[type=number]::-moz-placeholder, .form-group input[type=email]::-moz-placeholder, .form-group input[type=password]::-moz-placeholder {
	color: rgba(97, 105, 119, .5);
	font-weight: normal;
}
.form-group input[type=number] {
	-moz-appearance: textfield;
}
.form-group input[type=number]::-webkit-inner-spin-button, .form-group input[type=number]::-webkit-outer-spin-button {
	margin: 0;
	-webkit-appearance: none;
}
.form-group select, .form-group select:focus {
	width: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	border-bottom: 1px #A2A2A2 dotted;
	border-radius: 0;
	background: none transparent;
	color: inherit;
	color: #616977;
	font-size: inherit;
	font-size: 1.8rem;
	vertical-align: middle;
	-webkit-appearance: none;
	appearance: none;
	box-shadow: none;
	box-sizing: border-box;

	-moz-appearance: none;
}
.form-group select::-ms-expand, .form-group select:focus::-ms-expand {
	display: none;
}
.form-group select::-ms-clear, .form-group select:focus::-ms-clear {
	display: none;
}
.form-group select::-ms-reveal, .form-group select:focus::-ms-reveal {
	display: none;
}
.form-group select option, .form-group select:focus option {
	color: #333;
}
.form-group select:focus {
	border-bottom: 1px #A2A2A2 solid;
}
.form-group p {
	color: #616977;
	font-size: 1.8rem;
}
.form-group .form-cashbook {
	color: #616977;
	font-size: 1.8rem;
}
.form-group .form-cashbook select, .form-group .form-cashbook select:focus {
	width: 100% !important;
	margin: 10px 0;
}
@media screen and (min-width: 554px) {
	.form-group .form-cashbook select, .form-group .form-cashbook select:focus {
		width: 30% !important;
		margin: 10px 5px;
	}
}
@media screen and (max-width: 554px) {
	.form-group .input-date-form {
		width: 40%;
		font-size: 1.2rem;
	}
}
.form-group .form-cashbook input {
	margin: 0 5px 0 0;
}

.text-o-guide + .form-group {
	margin: 32px 0;
}
.login .form-group {
	position: relative;
}
.login .form-group label {
	display: block;
	overflow: hidden;
	position: absolute;
	right: 2px;
	width: 11px;
	height: 100%;
	text-indent: -9999px;
}
.login .form-group label[for='mailAddress'] {
	background: url(/_/image/ico_user_01.png) no-repeat 0 50%;
	background-size: 11px 15px;
}
.login .form-group input[type=text], .login .form-group input[type=tel], .login .form-group input[type=number], .login .form-group input[type=email], .login .form-group input[type=password] {
	padding: 0 20px 0 .4rem;
}
.login .form-group .password-switch {
	position: absolute;
	bottom: 8px;
	right: 0;
	color: #b0b4bb;
	font-size: 20px;
	cursor: pointer;
}
.login .form-group .password-switch:hover {
	color: #666;
}
.profiling-register .form-group input[type=email] {
	background: url(/_/image/ico_user_01.png) no-repeat 0 50%;
	background-position: center right;
	background-size: 11px 15px;
}
.form-inline .form-group {
	display: inline-block;
	margin: 0;
	vertical-align: middle;
}


.form-inline .form-group input[size='5'] {
	width: 80px;
}
.form-inline .form-group input[size='4'] {
	width: 62px;
}
.form-inline .form-group input[size='3'] {
	width: 58px;
}
.form-inline .form-group input[size='2'] {
	width: 44px;
}

.form-inline .form-group select.theo-form-size2 {
	width: 50px;
}

.form-inline .form-group label {
	padding: 0 16px 0 0;
}
.form-inline .form-group:last-child label {
	padding: 0;
}
.form-group:last-child {
	margin-bottom: 0;
}
.form-o-select {
	display: block;
	overflow: hidden;
	position: relative;
}
.form-inline .form-o-select {
	display: inline-block;
}
.form-o-select select, .form-o-select select:focus {
	background: url(/_/image/ico_arrow_down.png) no-repeat 100% 1.25rem;
	background-size: 11px 6px;
}
.form-control {
	display: inline-block;
}
.form-inline select.form-control, .form-inline select.form-control:focus {
	width: auto;
	padding: 0 1.4rem 0 0;
}
@media (max-width: 700px) {
	.form-group .box-o-collapse > .jal-card {
		padding: 0 !important;
	}
}
.radio .form-control {
	width: auto;
}
.has-error .control-label {
	color: #00263F;
}
.has-error .form-control, .has-error .form-control:focus, .has-error input[type=text], .has-error input[type=tel], .has-error input[type=number], .has-error input[type=email], .has-error input[type=password] {
	border-bottom: 1px #F29191 solid;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.has-error input[type=text], .has-error input[type=tel], .has-error input[type=number], .has-error input[type=email], .has-error input[type=password] {
	background: #FFF;
}
.has-error input[type=text], .has-error input[type=email] {
	color: #E22323;
}
#customerBirthYear + label, #customerBirthMonth + label, #customerBirthDay + label {
	margin: 0;
	font-size: 1.8rem;
	vertical-align: middle;
}
/* checkbox & radio */
.checkbox {
	margin: 0 0 16px;
}
.checkbox.mod-extension {
	margin: 0 0 32px;
}
.checkbox label {
	padding: 0 0 0 20px;
	cursor: pointer;
}
.checkbox input[type=checkbox] {
	display: none;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	background: none transparent;
	color: inherit;
	font-size: inherit;
	vertical-align: middle;
	-webkit-appearance: none;
	appearance: none;
	box-shadow: none;
	box-sizing: border-box;

	-moz-appearance: none;
}
.checkbox input[type=checkbox]::-ms-expand {
	display: none;
}
.checkbox input[type=checkbox]::-ms-clear {
	display: none;
}
.checkbox input[type=checkbox]::-ms-reveal {
	display: none;
}
.checkbox input[type=checkbox] + span {
	display: block;
	position: relative;
	padding: 0 0 0 10px;
	color: #616977;
	font-size: 1.4rem;
}
.checkbox input[type=checkbox] + span:before {
	content: ' ';
	position: absolute;
	top: 1px;
	left: -20px;
	width: 18px;
	height: 18px;
	margin: 0;
	border: 1px #D2D2D2 solid;
	border-radius: 2px;
	background: #F5F5F5;
}
.checkbox input[type=checkbox]:checked + span:before {
	border: 1px #0082F9 solid;
	background: #FFF;
}
.checkbox input[type=checkbox]:checked + span:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 5px;
	left: -16px;
	width: 10px;
	height: 7px;
	margin: 0;
	border-bottom: 2px #0082F9 solid;
	border-left: 2px #0082F9 solid;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.radio, .radio-inline {
	margin: 0 0 10px;
}
.radio label, .radio-inline label {
	padding: 0 0 0 20px;
	font-size: 1.8rem;
}
.radio input[type=radio], .radio-inline input[type=radio] {
	display: none;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	background: none transparent;
	color: inherit;
	font-size: inherit;
	vertical-align: middle;
	-webkit-appearance: none;
	appearance: none;
	box-shadow: none;
	box-sizing: border-box;

	-moz-appearance: none;
}
.radio input[type=radio]::-ms-expand, .radio-inline input[type=radio]::-ms-expand {
	display: none;
}
.radio input[type=radio]::-ms-clear, .radio-inline input[type=radio]::-ms-clear {
	display: none;
}
.radio input[type=radio]::-ms-reveal, .radio-inline input[type=radio]::-ms-reveal {
	display: none;
}
.radio input[type=radio] + span, .radio-inline input[type=radio] + span {
	padding: 0 0 0 6px;
}
.radio input[type=radio] + span:before, .radio-inline input[type=radio] + span:before {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 18px;
	height: 18px;
	margin-top: -9px;
	border: 1px #D2D2D2 solid;
	border-radius: 30px;
	background: #F5F5F5;
	box-sizing: border-box;
}
.radio input[type=radio]:checked + span:before, .radio-inline input[type=radio]:checked + span:before {
	border: 1px #0082F9 solid;
}
.radio input[type=radio]:checked + span:after, .radio-inline input[type=radio]:checked + span:after {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	left: 5px;
	width: 8px;
	height: 8px;
	margin-top: -4px;
	border-radius: 8px;
	background: #0082F9;
	box-sizing: border-box;
}
/* customize btn */
.btn {
	outline: none !important;
	border-radius: 100px;
	font-weight: bold;
}
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
	background: #007DFF;
	border: none;
}
.btn-docomo, .btn-docomo:hover, .btn-docomo:active, .btn-docomo:focus {
	background: white;
	border: 1px solid #cc0033;
	color: #cc0033;
}
.btn-docomo-primary, .btn-docomo-primary:hover, .btn-docomo-primary:active, .btn-docomo-primary:focus {
	background: #cc0033;
	border: none;
	color: #fff;
}
.btn-primary:hover {
	background: #026AD6;
}
.btn-primary.mod-detained, .btn-primary.mod-detained:hover, .btn-primary.mod-detained:active, .btn-primary.mod-detained:focus {
	border: 1px #007DFF solid;
	background: #FFF;
	color: #007DFF;
}
.btn-primary.mod-detained:hover {
	background: #E0EFFF;
}
.btn-primary.mod-gray, .btn-primary.mod-gray:hover, .btn-primary.mod-gray:active, .btn-primary.mod-gray:focus {
	border: 1px #616977 solid;
	background: #FFF;
	color: #616977;
}
.btn-primary[disabled='disabled'], .btn-primary.disabled, .btn-primary[disabled='disabled']:hover, .btn-primary.disabled:hover, .btn-primary[disabled='disabled']:active, .btn-primary.disabled:active, .btn-primary[disabled='disabled']:focus, .btn-primary.disabled:focus {
	border: none;
	background: #A8AFBA;
	color: #FFF;
}
.btn-default {
	background: #FFF;
	color: #A8AFBA;
	border: 1px solid #A8AFBA;
}
.btn-default:hover {
	background: #F6F6F6;
	color: #A8AFBA;
}
.btn-red {
	background: #FF2508;
	color: #FFF;
}
.btn-red:hover {
	background: #D01D07;
}
/* customize text */
.text-danger {
	margin: 0;
	padding: 0 0 2px;
	color: #E22323 !important;
	font-weight: bold;
	font-size: 1.4rem;
	list-style: none;
}
#allErrorList > div:first-child .text-danger, #allErrorList > .text-danger {
	margin: 0;
}
.text-danger a {
	color: #E22323;
}
.login .text-danger {
	margin: 0 -37px;
	padding: 15px 0;
	background: #FAFAFA;
	color: #E22323;
	font-size: 1.2rem;
	text-align: center;
}
.login .text-danger li:before {
	content: ' ';
	display: inline-block;
	width: 17px;
	height: 17px;
	margin: 0 4px 0 0;
	background: url(/_/image/ico_minus.png) no-repeat 0 0;
	background-size: 17px;
	vertical-align: middle;
}
.help-block {
	color: #616977;
	font-size: 1.2rem;
}
/* customize alert */
.alert {
	margin: 0;
	border: none;
	background: #FAFAFA;
	font-size: 1.2rem;
}
.alert p {
	position: relative;
	padding: 0 0 0 20px;
}
.alert p.no-indent {
	padding: 0;
}
.alert p:before {
	content: ' ';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 17px;
	height: 17px;
}
.alert.alert-success {
	color: #065FE3;
}
.alert.alert-success p:before {
	background: url(/_/image/ico_check.png) no-repeat 0 0;
	background-size: 17px;
}
.alert.alert-danger {
	color: #E22323;
}
.alert.alert-danger p:before {
	background: url(/_/image/ico_minus.png) no-repeat 0 0;
	background-size: 17px;
}
.alert + .alert {
	margin-top: 10px;
}
/* customize collapse */
.collapse p a, .collapsing p a {
	color: #616977;
	text-decoration: underline;
}
/* add style --------------------------- */
/* add layout */
.layout-o-center {
	text-align: center;
}
.layout-o-center-force {
	text-align: center !important;
}
.layout-o-right {
	text-align: right;
}
.layout-o-left {
	text-align: left !important;
}
.layout-o-image {
	margin: 24px auto 38px;
}
.wrapper-page-title + div .layout-o-image {
	margin: 0 auto 38px;
}
.layout-o-line-checkbox {
	margin-left: 32px;
}
.layout-o-payout {
	display: table;
	width: 100%;
	table-layout: fixed;
}
.layout-o-payout span {
	display: table-cell;
	color: #616977;
	font-size: 1.2rem;
	vertical-align: baseline;
}
.layout-o-payout span:nth-child(1) {
	width: 40%;
}
.layout-o-payout span:nth-child(2) {
	width: 30%;
}
.layout-o-payout span:nth-child(3) {
	width: 30%;
	text-align: right;
}
@media screen and (max-width: 554px) {
	.layout-o-payout span:nth-child(1) {
		width: 30%;
	}
	.layout-o-payout span:nth-child(2) {
		width: 45%;
	}
	.layout-o-payout span:nth-child(3) {
		width: 25%;
		text-align: right;
	}
	.list-o-payout li span:nth-child(2) {
		font-size: 1.2rem !important;
	}
	.list-o-payout li span:nth-child(3) {
		font-size: 1.1rem !important;
	}
}
/* add box */
.collapse.box-o-inner, .collapsing.box-o-inner {
	margin: 0 0 0 -10px;
}
.box-o-default *:last-child, .box-o-border-gray *:last-child, .box-o-gray *:last-child {
	margin-bottom: 0;
}
.box-o-default hr, .box-o-border-gray hr, .box-o-gray hr {
	height: 1px;
	background: #CCC;
}
.box-o-default h2, .box-o-border-gray h2, .box-o-gray h2 {
	margin: 24px 0 16px;
	font-size: 1.6rem;
}
.box-o-default h2:first-child, .box-o-border-gray h2:first-child, .box-o-gray h2:first-child {
	margin: 0 0 16px;
}
.box-o-default .section-title, .box-o-border-gray .section-title, .box-o-gray .section-title {
	margin: 0 0 16px;
}
.box-o-default ul > li:last-child .link-o-file, .box-o-border-gray ul > li:last-child .link-o-file, .box-o-gray ul > li:last-child .link-o-file {
	margin: 0;
}
.box-o-default.mode-no-margin, .box-o-border-gray.mode-no-margin, .box-o-gray.mode-no-margin {
	margin: 0;
	padding: 0;
}
.box-o-border-gray {
	margin: 0 0 18px;
	padding: 18px;
	border: 1px #E6EBF2 solid;
}
.box-o-inner, .box-o-gray {
	margin: 18px 0 0;
}
.js-documents > .box-o-inner:first-child, .js-documents > .box-o-gray:first-child {
	margin: 0;
}
.box-o-inner {
	padding: 0 .5rem;
}
.box-o-gray {
	padding: 15px;
	background: #FAFAFA;
}
.box-o-gray.mod-full {
	margin-right: -37px;
	margin-bottom: 40px;
	margin-left: -37px;
	padding: 36px 37px;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}
.box-o-light-blue {
	background: #EEF5FF;
}
.mod-full.fill-gray {
	position: absolute;
	height: 50px;
	width: 100%;
	background-color: #fafafa;
	box-sizing: content-box;
}
.box-o-gray.mod-full:last-child {
	margin-bottom: 0;
}
.top .box-o-gray.mod-full {
	padding-bottom: 54px;
}
.box-o-gray:first-child {
	margin-top: 0;
}
.box-o-alert {
	background: #FAFAFA;
}
.box-o-alert .alert {
	margin: 0;
	padding: 16px 0;
}
.box-o-default {
	padding: 0 0 18px;
}
.radio + .collapsing .box-o-default {
	margin: -2px 0 0;
	padding: 0 0 10px;
}
.box-o-collapse {
	padding: 0 0 16px;
}
.box-o-collapse.mod-inner {
	padding: 10px 0 1px;
}
.box-o-collapse .box-title {
	color: #00263F;
	font-size: 1.6rem;
}
.box-o-collapse .box-title.mod-parallel {
	margin: 0 0 2px 2.5rem;
	color: #616977;
	font-size: 1.2rem;
}
.box-o-collapse p, .box-o-collapse ol, .box-o-collapse ul, .box-o-collapse dl {
	color: #616977;
	font-size: 1.2rem;
}
.box-o-collapse p {
	margin: 0 0 8px;
}
.box-o-collapse p:last-child {
	margin-bottom: 0;
}
.box-o-collapse dl {
	margin: 0 0 16px;
}
.box-o-collapse dl:last-child {
	margin: 0;
}
.box-o-collapse dl > dt {
	margin: 4px 0;
	font-weight: normal;
}
.box-o-collapse dl > dt:first-child {
	margin: 0 0 4px;
}
.box-o-collapse dl > dd {
	padding: 0 0 0 1.5rem;
	text-indent: -1.5rem;
}
.box-o-address {
	padding: 0 0 0 .4rem;
	text-indent: -.4rem;
}
.box-o-address p {
	display: inline;
}
.box-o-status {
	margin: 0 0 50px;
	padding: 8px 0;
	background: #E5EFFB;
	text-align: center;
}
.box-o-section {
	margin: 0 0 72px;
}
.box-o-section:last-child {
	margin-bottom: 0;
}
.box-o-graph {
	padding: 84px 0 18px;
}
/* identity */
.box-o-select.is-inactive {
	display: none;
}
.box-o-content {
	display: none;
}
.box-o-content.is-active {
	display: block;
}
.box-o-content.mod-extension {
	margin: 32px 0 0;
}
.box-o-indent {
	padding: 0 0 0 30px;
}
.box-o-centered {
	margin: 0 auto;
	max-width: 370px;
}
.wrapper-identity-text div {
	display: none;
}
.wrapper-identity-text div.is-active {
	display: block;
}
.wrapper-identity-note > div {
	display: none;
}
.wrapper-identity-note > div.is-active {
	display: block;
}
/* 保有銘柄 */
.box-o-asset-table h2:after {
	margin: 0 0 0 8px;
	font-size: 1.2rem;
}
.growth .box-o-asset-table h2:after {
	content: '株式中心に運用';
}
.income .box-o-asset-table h2:after {
	content: '債券中心に運用';
}
.inflation .box-o-asset-table h2:after {
	content: '実物資産中心に運用';
}
/* add list */
.list-o-none {
	margin: 0;
	padding: 0;
}
.list-o-none > li {
	margin: 32px 0 0;
	color: #616977;
	font-size: 1.6rem;
	list-style-type: none;
}
.list-o-none > li:first-child {
	margin: 0;
}
.list-o-none > li:last-child > *:last-child {
	margin-bottom: 0;
}
.list-o-default, .list-o-asterisk, .list-o-minus {
	margin: 0;
	padding: 0 0 0 1rem;
	text-indent: -1rem;
}
.list-o-default > li, .list-o-asterisk > li, .list-o-minus > li {
	margin: 0 0 12px;
	color: #616977;
	font-size: 1.6rem;
	list-style-type: none;
}
.list-o-default > li:before, .list-o-asterisk > li:before, .list-o-minus > li:before {
	margin: 0 .5rem 0 0;
}
.list-o-default > li:before {
	content: '･';
}
.list-o-asterisk > li {
	font-size: 1.2rem;
}
.list-o-asterisk > li:before {
	content: '*';
}
.list-o-minus > li:before {
	content: '-';
}
.list-o-default, .list-o-asterisk, .list-o-minus, .list-o-disclaimer {
	margin: 0;
	padding: 0 0 0 1rem;
}
.list-o-disclaimer > li {
	margin: 0 0 12px;
	color: #616977;
	list-style-type: none;
	font-size: 1.2rem;
}
.list-o-disclaimer > li:before {
	margin: 0 .5rem 0 0;
}
.list-o-number {
	margin: 0 0 18px;
	padding: 0 0 0 3rem;
	color: #616977;
	font-size: 1.4rem;
}
.list-o-number > li {
	margin: 16px 0 0;
}
.list-o-number > li > p {
	margin: 0 0 4px;
	font-size: 1.4rem;
}
.list-o-number-circle {
	padding: 0 0 0 1.25rem;
	color: #616977;
	font-size: 1.2rem;
}
.list-o-number-circle li {
	position: relative;
	list-style: none;
}
.list-o-number-circle li:before {
	display: block;
	position: absolute;
	top: 0;
	left: -1.25rem;
	color: #616977;
}
.list-o-number-circle li:nth-child(1):before {
	content: '①';
}
.list-o-number-circle li:nth-child(2):before {
	content: '②';
}
.list-o-number-circle li:nth-child(3):before {
	content: '③';
}
.list-o-number-circle li:nth-child(4):before {
	content: '④';
}
.list-o-number-circle li:nth-child(5):before {
	content: '⑤';
}
.list-o-number-circle li:nth-child(6):before {
	content: '⑥';
}
.list-o-number-circle li:nth-child(7):before {
	content: '⑦';
}
.list-o-number-circle li:nth-child(8):before {
	content: '⑧';
}
.list-o-number-circle li:nth-child(9):before {
	content: '⑨';
}
.list-o-number-circle li:nth-child(10):before {
	content: '⑩';
}
.list-o-file {
	margin: 0;
}
.list-o-file.mod-extension {
	margin: 0 0 32px;
}
.list-o-file > li {
	position: relative;
	font-size: 1.6rem;
}
.list-o-file > li a, .list-o-file > li span {
	color: #616977;
	font-size: 1.6rem;
}
.list-o-file > li a.mod-small, .list-o-file > li span.mod-small {
	font-size: 1.2rem;
}
.list-o-file > li.js-file-stored, .list-o-file > li.js-file-selected {
	border-bottom: 1px #E6EBF2 solid;
}
.list-o-file > li:first-of-type.js-file-stored, .list-o-file > li:first-of-type.js-file-selected {
	border-top: 1px #E6EBF2 solid;
}
.list-o-file > li.js-file-stored {
	padding: 16px 105px 16px 0;
}
.list-o-file > li.js-file-selected {
	position: relative;
	margin: -1px 0 0;
	padding: 16px 0;
}
.list-o-file > li:last-child .js-file-upload {
	display: none;
}
.list-o-file.mod-multi {
	border-top: 1px #E6EBF2 solid;
}
.list-o-file.mod-multi li {
	padding: 20px 0;
	border-bottom: 1px #E6EBF2 solid;
}
.list-o-file-div {
	margin: 0;
}
.list-o-file-div > li {
	position: relative;
	border-bottom: 1px #E6EBF2 solid;
	color: #616977;
	font-size: 1.6rem;
}
.list-o-file-div > li a {
	margin: 0;
	padding: 14px 0 0;
	font-size: 1.4rem;
}
.list-o-file-div > li a, .list-o-file-div > li a:hover {
	color: #007DFF;
}
.list-o-file-div > li:first-child a {
	padding: 0;
}
.list-o-file-div > li span {
	display: block;
	padding: 0 0 12px;
	color: #007DFF;
	font-size: 1.1rem;
}
.list-o-file-margin {
	margin: 10px 0;
}
.list-o-file-margin > li {
	margin: 0 0 10px;
}
.list-o-information li {
	position: relative;
	min-height: 78px;
	padding: 16px 0;
	border-bottom: 1px #E6EBF2 solid;
}
.list-o-information li .js-unread-mark, .list-o-information li .js-opened-mark {
	overflow: hidden;
	position: absolute;
	top: 50%;
	left: 0;
	width: 10px;
	height: 10px;
	margin: -5px 0 0;
	text-indent: -9999px;
}
.list-o-information li .js-unread-mark:before {
	content: ' ';
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background: #0065DD;
}
.list-o-information li .js-opened-mark {
	display: none;
}
.list-o-information li:first-child {
	margin: -10px 0 0;
}
.list-o-information li a {
	color: #007DFF;
}
.list-o-information li a + .text-o-default {
	margin: 0;
	padding: 0 0 0 32px;
}
.list-o-information li.js-opened a {
	color: #616977;
}
.list-o-dl-indent {
	margin-bottom: 0;
}
.list-o-dl-indent > dt {
	margin: 0 0 8px -.75rem;
	padding: 0 0 0 1rem;
	color: #616977;
	font-weight: normal;
	font-size: 1.2rem;
	text-indent: -1rem;
}
.list-o-dl-indent > dd {
	margin: 0 0 8px;
	padding: 0 0 0 1.75rem;
	color: #616977;
	font-size: 1.2rem;
	text-indent: -1.75rem;
}
.list-o-dl-indent > dd:last-child {
	margin: 0;
}
.list-o-dl-assets > dt, .list-o-dl-assets > dd {
	color: #007DFF;
}
.list-o-dl-assets > dt {
	font-size: 1.2rem;
	line-height: 3;
}
.list-o-dl-assets > dd {
	font-size: 2.4rem;
}
.list-o-dl-table {
	display: table;
	table-layout: fixed;
}
.list-o-dl-table > dt, .list-o-dl-table > dd {
	color: #616977;
	vertical-align: middle;
}
.list-o-dl-table > dt {
	display: table-cell;
	width: 130px;
	font-size: 1.4rem;
}
.list-o-dl-table > dd {
	display: table-cell;
	font-size: 1.8rem;
}
.list-o-dl-table > dd:before, .list-o-dl-table > dd:after {
	display: none;
}
.list-o-dl-attention {
	display: inline-block;
	color: #065FE3;
	vertical-align: top;
}
.guidance .list-o-dl-attention {
	margin: 0 0 11px;
}
.list-o-dl-attention + .list-o-dl-attention {
	margin-left: 4px;
}
.list-o-dl-attention dt {
	font-weight: 500;
	font-size: 1.4rem;
}
.list-o-dl-attention dt span {
	font-size: 5.4rem;
}
.list-o-dl-attention dt span.mod-middle {
	font-size: 4.4rem;
}
.list-o-dl-attention dt span.mod-small {
	font-size: 3.2rem;
}
.list-o-dl-attention dd {
	font-size: 1.1rem;
}
.list-o-payout {
	border-top: 1px #E6EBF2 solid;
}
.list-o-payout li {
	border-bottom: 1px #E6EBF2 solid;
}
.list-o-payout li span {
	padding: 18px 0;
	line-height: 1;
}
.list-o-payout li span:nth-child(1) {
	color: #616977;
	font-size: 1.3rem;
}
.list-o-payout li span:nth-child(2) {
	color: #616977;
	font-size: 1.4rem;
}
.list-o-payout li span:nth-child(2):after {
	content: ' ';
	display: inline-block;
	width: 18px;
	height: 15px;
	margin: 0 0 0 8px;
	vertical-align: bottom;
}
.list-o-payout li span:nth-child(2).is-withdrawal:after {
	background: url(/_/image/ico_payout_withdrawal.png) no-repeat 0 0;
	background-size: 18px 15px;
}
.list-o-payout li span:nth-child(2).is-deposit:after {
	background: url(/_/image/ico_payout_deposit.png) no-repeat 0 0;
	background-size: 18px 15px;
}
.list-o-payout li span:nth-child(3) {
	color: #00263F;
	font-size: 1.3rem;
}
.list-o-payout:last-child {
	margin: 0;
}
.list-o-payout li b {
	font-weight: normal;
}
.list-o-step {
	padding: 0;
	list-style: none;
}
.list-o-step li {
	margin: 0 0 5px;
	border-radius: 5px;
	background: #FFF;
	box-shadow: rgba(0, 0, 0, .05) 0 0 3px 0;
}
.list-o-step li:last-child {
	margin: 0;
}
.list-o-step li a {
	display: block;
	position: relative;
	padding: 24px 36px 24px 52px;
	color: #007DFF;
	font-size: 1.4rem;
}
.list-o-step li a span {
	display: block;
	font-size: 1.2rem;
}
.list-o-step li a:hover {
	text-decoration: none;
}
.list-o-step li a:before, .list-o-step li a:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
}
.list-o-step li a:before {
	left: 16px;
	width: 31px;
	height: 31px;
	margin: -16px 0 0;
	background: url(/_/image/ico_list_01.png) no-repeat 0 0;
	background-size: 31px;
}
.list-o-step li a:after {
	right: 23px;
	width: 10px;
	height: 13px;
	margin: -7px 0 0;
	background: url(/_/image/ico_arrow_right_blue.png) no-repeat 0 0;
	background-size: 10px 13px;
}
.list-o-step-table {
	display: block;
	width: 300px;
	margin: 0 auto 16px;
	padding: 0;
	list-style: none;
}
.guidance .list-o-step-table {
	margin: 0 auto 48px;
}
.list-o-step-table li {
	width: 100%;
	margin: 0 0 8px;
	table-layout: fixed;
}
.list-o-step-table li figure {
	margin: 0 0 8px;
	text-align: center;
}
.list-o-step-table li h3 {
	margin: 0 0 8px;
	color: #00263F;
	font-weight: bold;
	font-size: 1.4rem;
	line-height: 1.4;
	text-align: center;
}
.list-o-step-table li h3 span {
	display: block;
	color: #616977;
	font-size: 1.2rem;
}
.list-o-step-table li p {
	color: #616977;
	font-size: 1.1rem;
	text-align: center;
}
.list-o-step-table li p.unit-sup {
	position: relative;
}
.list-o-step-table li p.unit-sup:before {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 1px;
	background: #B0B4BB;
}
.list-o-step-table li p.unit-sup span {
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 0 13px;
	background: #FFF;
	color: #616977;
}
.list-o-step-table li p:last-child {
	margin: 0 0 32px;
}
.list-o-news {
	border-top: 1px #CCE0F7 solid;
}
.list-o-news li {
	padding: 18px 0 8px;
	border-bottom: 1px #CCE0F7 solid;
}
.list-o-news li a, .list-o-news li a:visited, .list-o-news li a:active, .list-o-news li a:hover {
	text-decoration: none;
}
.list-o-news li a, .list-o-news li a:visited, .list-o-news li a:active, .list-o-news li a:hover, .list-o-news li a *, .list-o-news li a:visited *, .list-o-news li a:active *, .list-o-news li a:hover * {
	color: #007DFF;
}
.list-o-news li p {
	margin-bottom: 8px;
}
.list-o-normal {
	color: #616977;
	font-size: 1.4rem;
	line-height: 1.5;
}
.list-o-normal li {
	margin: 0 0 8px;
}
.list-o-normal.mod-middle {
	font-size: 1.6rem;
}
.list-o-normal.mod-extension {
	margin: 0 0 32px;
}
/* add table */
.table-o-bank {
	margin: 0 auto 40px;
	table-layout: fixed;
}
.table-o-bank:first-child {
	margin: 0 0 40px;
}
.table-o-bank.mod-unregistered {
	margin: 0 0 10px;
}
.table-o-bank.mod-plussender {
	margin: 0 auto 10px;
}
.table-o-bank tr th, .table-o-bank tr td {
	padding: 0 0 10px;
	color: #616977;
	font-weight: normal;
	text-align: left;
	vertical-align: baseline;
}
.table-o-bank tr th {
	width: 125px;
	font-size: 1.4rem;
}
.table-o-bank tr th span {
	display: inline-block;
}
.table-o-bank tr td {
	font-size: 1.8rem;
}
.table-o-bank tr td p {
	font-size: 1.8rem;
}
.table-o-bank tr td p span {
	padding: 0;
}
.table-o-bank tr td p span + span {
	margin: 0 0 0 8px;
}
.table-o-bank tr:last-child th, .table-o-bank tr:last-child td {
	padding: 0;
}
.table-o-bank .form-inline label {
	font-size: 1.6rem;
}
.table-o-bank .form-inline label input {
	width: auto;
}
.table-o-bank .form-inline label input[size='5'] {
	width: 80px;
}
.table-o-bank .form-inline label input[size='4'] {
	width: 62px;
}
.table-o-bank .form-inline label input[size='2'] {
	width: 44px;
}
.table-o-portfolio {
	border-right: 1px #E6EBF2 solid;
	border-bottom: 1px #E6EBF2 solid;
}
.table-o-portfolio thead {
	color: #00263F;
}
.table-o-portfolio thead tr th {
	border: 1px #E6EBF2 solid;
	border-bottom-width: 0;
}
.table-o-portfolio tbody tr:hover td:first-child {
	background: #E9E9E9;
}
.table-o-portfolio th, .table-o-portfolio td {
	font-size: 1.2rem;
	vertical-align: middle;
}
.table-o-portfolio th:first-child, .table-o-portfolio td:first-child {
	background: #F5F5F5;
}
.table-o-portfolio th:nth-child(2), .table-o-portfolio td:nth-child(2) {
	width: 25%;
}
.table-o-portfolio th:nth-child(3), .table-o-portfolio td:nth-child(3), .table-o-portfolio th:nth-child(4), .table-o-portfolio td:nth-child(4) {
	width: 60px;
}
.table-o-portfolio th {
	text-align: center;
}
.table-o-portfolio td:nth-child(2), .table-o-portfolio td:nth-child(3), .table-o-portfolio td:nth-child(4) {
	text-align: right;
}
/* 保有銘柄 */
.table.table-o-asset {
	width: 100%;
	margin: 0 0 50px;
	border: none;
	table-layout: fixed;
}
.table.table-o-asset th, .table.table-o-asset td {
	color: #616977;
	font-weight: normal;
	font-size: 1.2rem;
	vertical-align: middle;
}
.table.table-o-asset th:nth-child(1), .table.table-o-asset td:nth-child(1) {
	width: 55px;
}
.table.table-o-asset th:nth-child(2), .table.table-o-asset td:nth-child(2) {
	width: 100%;
}
.table.table-o-asset th:nth-child(3), .table.table-o-asset td:nth-child(3) {
	width: 110px;
	text-align: right;
}
.table.table-o-asset th {
	padding: 0 0 4px;
	border: none;
}
.table.table-o-asset td {
	height: 50px;
	padding: 0;
	border-right: none;
	border-left: none;
	vertical-align: middle;
}
.table.table-o-asset td:first-child:before {
	content: ' ';
	display: inline-block;
	width: 9px;
	height: 9px;
	margin: 0 2px 0 0;
	border-radius: 50%;
}
.growth .box-o-asset-table .table.table-o-asset td:first-child:before {
	background: #69DCFA;
}
.growth .box-o-asset-table.theo-green .table.table-o-asset td:first-child:before {
	background: #85e7ac;
}
.income .box-o-asset-table .table.table-o-asset td:first-child:before {
	background: #0065DD;
}
.inflation .box-o-asset-table .table.table-o-asset td:first-child:before {
	background: #004EAA;
}
.table.table-o-asset td .number-amount {
	display: block;
}
.table.table-o-asset td .number-amount:first-child {
	color: #616977;
	font-size: 1.6rem;
}
.table.table-o-asset td .number-amount:last-child {
	font-size: 1.2rem;
}
.table.table-o-asset td .number-amount.number-positive:last-child {
	color: #007DFF;
}
.table.table-o-asset td .number-amount.number-negative:last-child {
	color: #C33;
}
/* add btn */
.btn-lg {
	font-size: 1.6rem;
	height: 5rem;
	line-height: 5rem;
	padding: 0 32px;
	min-width: 300px;
}
.btn-md {
	display: block;
	width: 270px;
	height: 4rem;
	line-height: 4rem;
	padding: 0 24px;
	margin: 0 auto 16px;
	font-size: 1.4rem;
}
.btn-md-portfolio {
	display: block;
	width: 150px;
	height: 40px;
	line-height: 2.9;
	padding: 0 24px;
	margin: 0 auto 16px;
	font-size: 1.4rem;
}

@media screen and (min-width: 554px) {
	.btn-md-portfolio {
		width: 200px;
	}
}

@media screen and (max-width: 554px) {
	.btn-line-composition {
		margin: 5px;
	}
}

.btn-line{
	display: flex;
	justify-content: space-around;
}

.btn-sm {
	font-size: 1.2rem;
	height: 3rem;
	line-height: 3rem;
	padding: 0 16px;
}
a.btn-md {
	line-height: 4rem;
}
.wrapper-page-title + * > .btn-md {
	margin: 0 auto 20px;
}
.btn-md + .btn-md {
	margin: 16px auto;
}
.js-file-stored .btn-o-layout, .js-file-selected .btn-o-layout {
	display: inline-block !important;
	position: relative;
	width: 100%;
	padding: 0 30px 0 0;
}
.js-file-stored .btn-o-layout .btn-o-delete, .js-file-selected .btn-o-layout .btn-o-delete {
	position: absolute;
	top: 50%;
	right: 0;
	width: 20px;
	height: 20px;
	margin: -10px 10px 0 0;
	border: 0;
	background: url(/_/image/ico_timesRound_01.png) no-repeat 0 0;
	background-size: 20px;
	text-indent: -9999px;
}
.btn-o-upload {
	margin: 16px auto 36px;
}
.btn-o-upload .input-group {
	margin: 0 auto;
}
.btn-o-upload label {
	width: 210px;
	height: 44px;
}
.btn-o-upload label, .btn-o-upload label:hover, .btn-o-upload label:active, .btn-o-upload label:focus, .btn-o-upload label:active:hover {
	border: 1px #0082F9 solid;
	background: #FFF;
}
.btn-o-upload label .icon-span-filestyle {
	display: none;
}
.btn-o-upload label .buttonText {
	position: relative;
	color: #007DFF;
	font-size: 1.6rem;
	line-height: 42px;
}
.btn-o-upload label .buttonText:before {
	content: ' ';
	display: inline-block;
	width: 16px;
	height: 14px;
	margin: -2px 6px 0 0;
	background: #FFF url(/_/image/ico_file_upload.png) no-repeat 0 0;
	background-size: 16px 14px;
	vertical-align: middle;
}
.btn-o-upload + span {
	display: none;
}
.js-file-selected .btn-o-upload {
	display: none;
}
.btn-o-upload .btn[disabled='disabled'], .btn-o-upload .btn.disabled, .btn-o-upload .btn[disabled='disabled']:hover, .btn-o-upload .btn.disabled:hover, .btn-o-upload .btn[disabled='disabled']:active, .btn-o-upload .btn.disabled:active, .btn-o-upload .btn[disabled='disabled']:focus, .btn-o-upload .btn.disabled:focus {
	border: 1px #A8AFBA solid;
	background: #FFF;
	color: #A8AFBA;
}
.btn-o-upload .btn[disabled='disabled'] .buttonText, .btn-o-upload .btn.disabled .buttonText {
	color: #A8AFBA;
}
.btn-o-upload .btn[disabled='disabled'] .buttonText:before, .btn-o-upload .btn.disabled .buttonText:before {
	background-image: url(/_/image/ico_file_upload_gray.png);
}
.btn-o-upload .btn .badge {
	display: none;
}
/* add text */
.text-o-default {
	margin: 0 0 16px;
	color: #616977;
	font-size: 1.4rem !important;
	line-height: 1.5;
	letter-spacing: normal;
}
.text-o-default-bold {
	font-size: 1.4rem;
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: normal;
	margin: 24px 0 12px;

}
.text-o-default a {
	border-bottom: 1px #111 dotted;
	color: #007DFF;
}
.text-o-default a:hover {
	text-decoration: none;
}
.text-o-warn {
	margin: 16px 0 0;
	color: #616977;
	font-size: 1.4rem !important;
	line-height: 1.5;
	letter-spacing: normal;
}
.box-o-border-gray + .text-o-default, .box-o-gray + .text-o-default {
	margin: 18px 0 18px;
}
.collapse + .text-o-default {
	margin: 18px 0;
}
.form-o-select + .text-o-default {
	margin: 14px 0;
}
.box-o-status .text-o-default {
	margin: 0;
}
.text-o-default.mod-small, .text-o-default .mod-small {
	font-size: 1.2rem !important;
}
.text-o-default.mod-middle {
	font-size: 1.6rem !important;
}
.text-o-default.mod-large {
	font-size: 1.8rem !important;
}
.text-o-default.mod-line {
	padding: 18px 0;
	border-top: 1px #E5E5E5 solid;
	border-bottom: 1px #E5E5E5 solid;
}
.text-o-lead {
	margin: 0 0 32px;
	color: #616977;
	font-size: 1.2rem !important;
	line-height: 1.5;
	letter-spacing: normal;
}
.text-o-lead-left {
	margin: 0 0 32px;
	color: #616977;
	font-size: 1.2rem !important;
	line-height: 1.5;
	letter-spacing: normal;
}
.guidance .text-o-default {
	margin: 0 0 43px;
}
.text-o-modal {
	margin: 0 0 24px;
}
.text-o-modal.mod-narrow {
	margin: 0 0 12px;
}
.text-o-modal.mod-close {
	margin: 0 0 4px;
}
.guidance .text-o-modal {
	margin: 0 0 43px;
}
.text-o-step {
	margin: 0 0 12px;
	color: #065FE3;
	font-weight: normal;
	font-size: 1.6rem;
}
.text-o-amount {
	padding: 0 0 7px 4px;
	font-size: 1.8rem;
}
.text-o-large {
	font-size: 4.5rem !important;
}
.text-o-large.mod-number {
	margin: 0 0 14px;
	color: #616977;
	line-height: 1;
}
.text-o-large .number-unit {
	font-size: 2.4rem !important;
}
.text-o-middle {
	font-size: 2.4rem !important;
}
.text-o-middle.mod-number {
	color: #065FE3;
}
.text-o-middle.mod-number .number-negative {
	color: #616977;
}
.text-o-middle .number-unit {
	font-size: 1.4rem !important;
}
.text-o-small {
	color: #616977;
	font-size: 1.2rem !important;
}
.text-o-small.mod-title {
	color: #00263F;
	font-weight: bold;
}
.text-o-small.mod-title span {
	display: block;
	color: #616977;
	font-size: 1.1rem;
}
.text-o-price {
	margin: 0 0 0 24px;
	font-size: 1.8rem;
}
.text-o-price + .text-o-price {
	margin: 0;
}
.text-o-asterisk {
	margin: 0 0 18px;
	padding: 0 0 0 1.25rem;
	color: #616977;
	font-size: 1.2rem !important;
	text-indent: -1.25rem;
}
.collapse + .text-o-asterisk {
	margin: 18px 0;
}
.text-o-asterisk:before {
	content: '*';
	margin: 0 .5rem 0 0;
}
.text-o-asterisk.mod-red {
	color: #C33;
}
.text-o-asterisk.mod-close {
	margin: 0 0 0;
}
.text-o-guide {
	margin: 0 0 32px;
	color: #616977;
	font-size: 1.6rem !important;
	line-height: 1.625;
}
.wrapper-identity-text .text-o-guide {
	margin: 0 0 16px;
}
.text-o-note {
	margin: 16px 0 0;
	color: #616977;
	font-size: 1.2rem !important;
	text-align: center;
}
.guidance .text-o-note {
	margin: 0 0 20px;
}
.text-o-status {
	position: absolute;
	top: 50%;
	right: 0;
	height: 20px;
	margin: -10px 0 0;
	padding: 0 5px;
	background: #E6EBF3;
	color: #616977;
	font-size: 1.2rem;
	line-height: 20px;
}
.text-o-information-date {
	font-size: 1.4rem;
}
.text-o-information-title {
	font-size: 1.8rem;
}
.text-o-information-date, .text-o-information-title {
	display: block;
}
.list-o-information .text-o-information-date, .list-o-information .text-o-information-title {
	padding: 0 0 0 32px;
}
.text-o-news-title {
	margin: 0 0 8px;
	color: #616977;
	font-size: 1.4rem;
}
.text-o-enclose {
	display: inline-block;
	margin: 0 0 0 6px;
	padding: 2px 5px;
	background: #C6CAD1;
	color: #FFF;
	font-size: 1rem;
	line-height: 1;
	vertical-align: middle;
}
.text-o-form-ex {
	margin: 0;
	color: #616977;
	font-size: 1.2rem !important;
}
.text-o-checked {
	display: inline-block;
	position: relative;
	padding-left: 13px;
	color: #065FE3;
}
.text-o-checked:before {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 10px;
	height: 8px;
	margin: -4px 0 0;
	background: url(/_/image/ico_check_reverse.png) no-repeat 0 0;
	background-size: 10px 8px;
}
.disabled .text-o-checked {
	color: #00263F;
}
.text-o-dark-color {
	color: #00263F;
}
/* add link */
.link-o-default {
	display: inline-block;
	padding: 6px 12px;
	font-size: 1.4rem;
}
.link-o-default, .link-o-default:hover {
	color: #007DFF;
	text-decoration: none;
}
.link-o-dotted, .link-o-caret, .link-o-file, .link-o-modal {
	display: inline-block;
	position: relative;
	margin: 0;
	font-size: 1.2rem;
}
.link-o-dotted, .link-o-caret, .link-o-file, .link-o-modal, .link-o-dotted:hover, .link-o-caret:hover, .link-o-file:hover, .link-o-modal:hover, .link-o-dotted:active, .link-o-caret:active, .link-o-file:active, .link-o-modal:active, .link-o-dotted:focus, .link-o-caret:focus, .link-o-file:focus, .link-o-modal:focus {
	text-decoration: none;
}
.link-o-dotted.mod-blue, .link-o-caret.mod-blue, .link-o-file.mod-blue, .link-o-modal.mod-blue, .link-o-dotted.mod-blue:hover, .link-o-caret.mod-blue:hover, .link-o-file.mod-blue:hover, .link-o-modal.mod-blue:hover, .link-o-dotted.mod-blue:active, .link-o-caret.mod-blue:active, .link-o-file.mod-blue:active, .link-o-modal.mod-blue:active, .link-o-dotted.mod-blue:focus, .link-o-caret.mod-blue:focus, .link-o-file.mod-blue:focus, .link-o-modal.mod-blue:focus {
	color: #007DFF;
}
.link-o-dotted.mod-extension, .link-o-caret.mod-extension, .link-o-file.mod-extension, .link-o-modal.mod-extension {
	margin: 0 0 32px;
}
.link-o-dotted, .link-o-caret, .link-o-file, .link-o-dotted:hover, .link-o-caret:hover, .link-o-file:hover, .link-o-dotted:active, .link-o-caret:active, .link-o-file:active, .link-o-dotted:focus, .link-o-caret:focus, .link-o-file:focus {
	color: #616977;
}
.link-o-modal, .link-o-modal:hover, .link-o-modal:active, .link-o-modal:focus {
	color: #007DFF;
}
.link-o-dotted {
	padding: 0 0 2px;
}
.link-o-dotted, .link-o-dotted:hover, .link-o-dotted:active, .link-o-dotted:focus {
	border-bottom: 1px #8A8A8A dotted;
}
.link-o-dotted.mod-blue, .link-o-dotted:hover.mod-blue, .link-o-dotted:active.mod-blue, .link-o-dotted:focus.mod-blue {
	border-bottom: 1px #0082F9 dotted;
}
li > .link-o-dotted {
	margin: 0;
	text-indent: 0;
}
.link-o-dotted.mod-inline {
	margin: 0 0 -3px;
	padding: 0;
}
.link-o-caret {
	padding: 0 12px 0 0;
	border: none;
	background: transparent;
}
.link-o-caret:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	width: 8px;
	height: 8px;
	margin: -5px 0 0;
	background: url(/_/image/ico_arrowRound_right_02.png) no-repeat 0 0;
	background-size: 8px;
}
.link-o-caret.mod-blue:after {
	background: url(/_/image/ico_arrowRound_right.png) no-repeat 0 0;
	background-size: 8px;
}
.link-o-file {
	padding: 0 12px 0 0;
}
.link-o-file:after {
	content: ' ';
	display: inline-block;
	width: 12px;
	height: 15px;
	margin: -2px 0 0 4px;
	background: url(/_/image/ico_file_01.png) no-repeat 0 0;
	background-size: 12px 15px;
	vertical-align: middle;
}
.list-o-file-div .link-o-file:after {
	margin: -5px 0 0 4px;
}
.list-o-file .link-o-file:after {
	background: url(/_/image/ico_file_02.png) no-repeat 0 0;
	background-size: 12px 15px;
}
.list-o-file .js-document-unviewed .link-o-file {
	color: #007DFF;
	font-weight: normal;
}
.list-o-file .js-document-unviewed .link-o-file:hover {
	text-decoration: underline;
}
.list-o-file .js-document-unviewed .link-o-file:after {
	background: url(/_/image/ico_file_01.png) no-repeat 0 0;
	background-size: 12px 15px;
}
.link-o-file li {
	margin: 0;
}
.link-o-modal {
	padding: 0 0 0 12px;
}
.link-o-modal:before {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 8px;
	height: 9px;
	margin: -5px 0 0;
	background: url(/_/image/ico_balloon_01.png) no-repeat 0 0;
	background-size: 8px 9px;
}
.list-o-links, .list-o-link-other {
	margin: 0 0 32px;
	padding: 0;
	border-top: 1px #E6EBF3 solid;
	list-style: none;
}
.list-o-links li, .list-o-link-other li {
	display: table;
	width: 100%;
	height: 60px;
	border-bottom: 1px #E6EBF3 solid;
	background: url(/_/image/ico_arrow_right_blue.png) no-repeat 100% 50%;
	background-size: 10px 13px;
}
.list-o-links a, .list-o-link-other a {
	display: table-cell;
	height: 75px;
	padding: 14px 2px;
	color: #007DFF;
	font-size: 1.6rem;
	line-height: 1.3;
	text-decoration: none;
	vertical-align: middle;
}
.list-o-links a span, .list-o-link-other a span {
	font-size: 1.2rem;
}
.list-o-links a span.text-o-sup, .list-o-link-other a span.text-o-sup {
	display: block;
	padding-right: 1em;
}
.list-o-links a span.text-o-plus, .list-o-link-other a span.text-o-plus {
	display: block;
	margin-left: 36px !important;
}
.list-o-links .link-o-card-driver, .list-o-link-other .link-o-card-driver, .list-o-links .link-o-card-health, .list-o-link-other .link-o-card-health, .list-o-links .link-o-card-mynumber01, .list-o-link-other .link-o-card-mynumber01, .list-o-links .link-o-card-mynumber02, .list-o-link-other .link-o-card-mynumber02 {
	position: relative;
	padding: 20px 2px 20px 84px;
}
.list-o-links .link-o-card-driver:before, .list-o-link-other .link-o-card-driver:before, .list-o-links .link-o-card-health:before, .list-o-link-other .link-o-card-health:before, .list-o-links .link-o-card-mynumber01:before, .list-o-link-other .link-o-card-mynumber01:before, .list-o-links .link-o-card-mynumber02:before, .list-o-link-other .link-o-card-mynumber02:before {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 10px;
	width: 62px;
	height: 39px;
	margin: -20px 0 0;
}
.list-o-links .link-o-card-driver:before, .list-o-link-other .link-o-card-driver:before {
	background: url(/_/image/img_card_driver.png) no-repeat 0 0;
	background-size: 62px 39px;
}
.list-o-links .link-o-card-health:before, .list-o-link-other .link-o-card-health:before {
	background: url(/_/image/img_card_health.png) no-repeat 0 0;
	background-size: 62px 39px;
}
.list-o-links .link-o-card-mynumber01:before, .list-o-link-other .link-o-card-mynumber01:before {
	background: url(/_/image/img_card_mynumber_01.png) no-repeat 0 0;
	background-size: 62px 39px;
}
.list-o-links .link-o-card-mynumber02:before, .list-o-link-other .link-o-card-mynumber02:before {
	background: url(/_/image/img_card_mynumber_02.png) no-repeat 0 0;
	background-size: 62px 39px;
}
.list-o-links.mod-extension, .list-o-link-other.mod-extension {
	margin: 0 0 32px;
}
.list-o-tab {
	display: table;
	width: 100%;
	margin: 0 0 25px;
	border-bottom: 1px #EFEFEF solid;
	table-layout: fixed;
}
.list-o-tab:before,
.list-o-tab:after {
	display: none;
}
.list-o-tab li {
	display: table-cell;
	float: none;
	font-size: 1.4rem;
	text-align: center;
}
.list-o-tab li a {
	padding: 10px 0;
	border: none;
	border-radius: 0;
	color: #A8AFB9;
}
.list-o-tab li a:hover {
	background: transparent;
}
.list-o-tab li.active {
	border-bottom: 1px #0E0E0E solid;
}
.list-o-tab li.active a {
	border: none !important;
	color: #00263F;
}
/* add wrapper */
.wrapper-portfolio {
	margin: 12px -10px 0;
	padding: 0 10px;
	border-top: 1px #CCE0F7 solid;
}
.wrapper-note {
	margin: 32px 0 0;
}
.checkbox .wrapper-note {
	margin: 12px 0 0;
}
.wrapper-note p:last-child {
	margin: 0;
}
.wrapper-annotation {
	padding: 16px 37px;
	background: #F5F5F5;
}
.wrapper-annotation *:last-child {
	margin: 0;
}
.wrapper-annotation a {
	color: #616977;
	text-decoration: underline;
}
.wrapper-annotation.is-hidden {
	display: none;
}
.wrapper-link {
	display: block;
	margin: 0 0 16px;
}
.wrapper-bank {
	width: 100%;
	margin: 0 0 32px;
}
.wrapper-bank > * {
	margin: 0;
	letter-spacing: -.4em;
}
.wrapper-bank > * > * {
	display: inline-block;
	letter-spacing: normal;
}
.wrapper-bank > * label {
	width: 120px;
}
.wrapper-bank > a {
	margin: 40px auto !important;
	letter-spacing: normal;
}
.wrapper-table {
	display: inline-block;
}
.wrapper-modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1002;
	width: 100%;
	height: 100%;
}
.wrapper-modal.is-active {
	display: block;
}
.wrapper-modal .inner {
	width: 100%;
	height: 100%;
	padding: 50px 34px;
	background: rgba(152, 161, 174, .95);
	text-align: center;

	overflow-y: scroll;
}
.wrapper-modal .box-o-modal {
	position: relative;
	max-width: 700px;
	margin: 0 auto;
}
.wrapper-modal h2, .wrapper-modal h3, .wrapper-modal p, .wrapper-modal ul {
	color: #FFF !important;
}
.wrapper-modal h2 {
	display: inline-block;
	margin: 0 auto 40px;
	border-bottom: 1px #FFF solid;
	font-size: 2rem;
	line-height: 1.75;
}
.wrapper-modal h3 {
	margin: 0 0 12px;
	font-size: 1.6rem;
	line-height: 1.5;
	text-align: left;
}
.wrapper-modal p {
	margin: 0 0 16px;
	font-size: 1.2rem;
	line-height: 1.75;
	text-align: left;
}
.wrapper-modal ul li {
	margin: 0 0 16px;
	color: #FFF;
	font-size: 1.4rem;
	text-align: left;
}
.wrapper-modal .btn-o-close {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 21px;
	height: 21px;
}
.wrapper-modal .btn-o-close span {
	position: absolute;
	top: 10px;
	right: 0;
	width: 22px;
	height: 1px;
	background: #FFF;
}
.wrapper-modal .btn-o-close span:first-child {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.wrapper-modal .btn-o-close span:last-child {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.wrapper-identity-text.modCenter {
	width: 365px;
	margin-right: auto;
	margin-left: auto;
}
/* add separate */
.hr-o-section {
	height: 30px;
	margin: 0;
	border: none;
}
/* add image */
.image-o-sendmail {
	display: block;
	overflow: hidden;
	width: 114px;
	height: 126px;
	margin: 0 auto;
	background: url(/_/image/ico_sendmail.png) no-repeat 50% 50%;
	background-size: 114px 126px;
	text-indent: -9999px;
}
.image-o-loading {
	width: 103px;
	height: 67px;
	margin: 30px 0 0;
}
.modal-o-default h4 {
	color: #00263f;
}
.modal-o-default h5 {
	font-size: 1.6rem;
	color: #00263f;
	margin-bottom: 16px;
}
.modal-o-default p {
	color: #616977;
	font-size: 1.4rem;
}
.modal-o-default p .text-small {
	font-size: 1.2rem;
}
.modal-o-default p .text-bold {
	font-weight: bold;
}
.modal-o-default .modal-content {
	border: none;
	box-shadow: none;
	border-radius: 3px;
	padding: 24px;
}
.modal-o-default .modal-header {
	text-align: center;
	border: none;
	padding: 20px;
}
.modal-o-default .modal-header button.close {
	margin-right: -16px;
	margin-top: -24px;
	font-weight: normal;
	font-size: 32px;
}
.modal-o-default .modal-body {
	padding: 0;
}
.modal-o-default .modal-body-center {
	text-align: center;
}
.modal-o-default .modal-section {
	margin-bottom: 32px;
}
.modal-o-default .modal-footer {
	padding: 15px;
	border: none;
}
.modal-o-default .btn-default {
	border-color: #007DFF;
	color: #007DFF;
}
.modal-o-default .btn-default:hover {
	background-color: #FFF;
}
.modal-o-default .btn-primary {
	background: #007DFF;
}
/* mod style --------------------------- */
/* add margin */
.margin-o-top {
	margin: 48px 0 36px;
}
.margin-o-top:last-child {
	margin: 48px 0 0;
}
.login .margin-o-top {
	margin: 64px auto 32px;
}
.login .margin-o-top + .margin-o-top {
	margin: 0 auto 45px;
}
.margin-o-left {
	margin-left: 1rem !important;
}
.margin-o-middle {
	margin-bottom: 32px !important;
}
div[class^='box-o-'] + .margin-o-middle {
	margin: 32px 0 !important;
}
.margin-o-small {
	margin-bottom: 26px !important;
}
.margin-o-negative {
	margin-right: -15px;
	margin-left: -15px;
}
/* color */
.mod-text-blue {
	color: #065FE3 !important;
}
.mod-text-blue-dark {
	color: #00263F !important;
}
.mod-text-alert {
	color: #E22323 !important;
}
.mod-text-default {
	color: #616977 !important;
}
/* text indent */
.mod-text-indent {
	padding: 0 0 0 .75rem;
	text-indent: -.75rem;
}
.mod-text-close {
	margin-bottom: 4px !important;
}
.mod-text-narrow {
	margin-bottom: 12px !important;
}
.mod-text-exp {
	margin-bottom: 28px !important;
}
.mod-text-extension {
	margin-bottom: 45px !important;
}
.is-pc.mod-inline {
	display: block;
}
@media (min-width: 320px) {
	.wrapper > .container > .inner .inner-contents {
		margin: 0 auto;
	}
	.dl-o-center {
		width: 280px;
		margin: 0 auto;
	}
	.dl-o-center > dt {
		overflow: hidden;
		float: left;
		clear: left;
		width: auto;
		min-width: 90px;
		white-space: nowrap;
		text-align: right;
		text-overflow: ellipsis;
	}
	.dl-o-center > dd {
		margin: 0 0 0 110px;
		text-align: right;
	}
}
@media (min-width: 374px) {
	.wrapper > .container > .inner .inner-contents {
		width: 100%;
		margin: 0 0 50px;
		padding: 0 16px;
	}

	/* グラフなど幅が足りなくなるページ用、対応が完了したら消す */
	.wrapper > .container > .inner .inner-contents .wide-padding {
		padding: 0 13px;
	}
}
@media (max-width: 373px) {
	.table-o-bank tr th {
		width: 90px;
	}
}
@media (min-width: 417px) {
	.content-o-narrow {
		width: 350px;
		margin: 0 auto;
	}
	.login .form-group {
		width: 350px;
	}
}
@media (min-width: 700px) {
	.wrapper > .container > .inner .inner-contents {
		width: 700px;
		margin: 0 auto 50px;
	}
	.navbar .wrapper > .container > .inner .inner-contents {
		padding: 0;
	}
	.wrapper > .navbar > .inner .inner-contents .navbar-brand {
		margin: 10px 10px 10px 0;
	}
	.navbar .container {
		max-width: 700px;
	}
	.navbar-header {
		float: none!important;
	}
	.navbar-toggle {
		display: block!important;
	}
	.navbar-nav .open .dropdown-menu li a {
		padding: 5px 15px 5px 25px;
		color: #007DFF;
	}
	.navbar-nav .open .dropdown-menu li a:hover {
		color: #065FE3;
	}
	.navbar-right .dropdown-toggle {
		padding: 12px 0 12px 15px;
	}
	footer > .inner .inner-contents {
		width: 700px;
		margin: 0 auto 10px;
		text-align: center;
	}
	footer > .inner .inner-contents ul {
		display: inline-block;
	}
	.table-o-portfolio th:nth-child(2), .table-o-portfolio td:nth-child(2) {
		width: 200px;
	}
	.table-o-portfolio th:nth-child(3), .table-o-portfolio td:nth-child(3), .table-o-portfolio th:nth-child(4), .table-o-portfolio td:nth-child(4) {
		width: 120px;
	}
	.list-o-dl-table > dt {
		text-align: left;
	}
	.wrapper-annotation p {
		display: block;
		width: 700px;
		margin: 0 auto !important;
	}
	.text-o-lead, .text-o-note {
		text-align: center !important;
	}
	.is-pc.mod-block {
		display: block;
	}
	.is-pc.mod-inline {
		display: inline;
	}
}
@media (max-width: 767px) {
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
		color: #065FE3;
	}
}
@media (min-width: 774px) {
	.wrapper > .container > .inner .inner-contents {
		padding: 0;
	}
	.br-xs {
		display: none;
	}
}

.blue-link a {
	height: 75px;
	color: #007DFF;
	font-size: 1.6rem;
	line-height: 2.3;
	text-decoration: none;
	vertical-align: middle;
}

.blue-link-small a {
	color: #007DFF;
	font-size: 1.2rem;
	text-decoration: none;
}

.remove-me {
	/* for HOTFIX 20170228 */
}

.controller-container {
	color: #616977;
	text-align: center;
	font-size: 1.3rem;
	max-width: 350px;
	margin: 12px auto 0;
}
.controller_label {
	text-align: left;
}
.controller_counter {
	margin-bottom: 12px;
	width: 100%;
	height: 5px;
	border-radius: 5px;
	background-color: #cfd3da;
	position: relative;
}
.controller_counter .ui-slider-range {
	height: 100%;
	border-radius: 5px;
}
.controller_counter_simple .ui-slider-range {
	background-color: #065fe3;
}
.controller_counter .ui-slider-handle {
	display: block;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	margin-top: -5px;
	margin-left: -10px;
	background-color: #fff;
	outline: none;
	box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);

	position: absolute;
	top: 0;
	cursor: default;
	-ms-touch-action: none;
	touch-action: none;
}
.controller_level {
	display: none;
	font-family: "Helvetica Neue", YuGothic, '游ゴシック', "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", Helvetica, Arial, sans-serif;
	font-weight: lighter;
	font-size: 1.6rem;
	line-height: 26.5px;
	color: #fff;
	vertical-align: middle;
	position: absolute;
	bottom: 14px;
	margin-left: -13.25px;
	background-size: 26.5px 29px;
	width: 26.5px;
	height: 29px;
}
.controller_counter_simple .controller_level {
	background-image: url("/portfolio/default.controller_balloon_3.png");
}
.controller_edit-btn {
	cursor: pointer;
	border-bottom: 1px dotted #8A8A8A;
	display: inline-block;
	height: 20px;
	position: relative;
}
.controller_edit-btn:hover {
	border-bottom-style: solid;
	text-decoration: none;
}
.controller_change-btn {
	cursor: pointer;
	display: inline-block;
	height: 20px;
	position: relative;
	padding-left: 14px;
}
.controller_change-btn:before {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 14px;
	height: 12px;
	margin: -8px 0 0 -1px;
	background: url(/_/image/ico_switch.png) no-repeat 0 0;
	background-size: 14px 12px;
}
.controller_change-btn:hover {
}

.form-group .list-default {
	padding-left: 24px;
	font-size: 1.4rem;
	color: #616977;
}

.btn-disable {
	opacity: 0.3;
	pointer-events: none;
}

.button-inline {

}
.button-inline .btn-md {
	margin: 0 10px;
	display: inline-block;
	width: auto;
	min-width: 100px;
}
.gray-background {
	background: #F7F7F7;
	padding: 20px;
	margin-bottom: 30px;
}

@media (max-width: 413px) {
	.button-inline .btn-md {
		margin: 0 5px;
	}
}

.payout-tip {
	font-size: 1.2rem;
	background: #fadfe1;
	padding: 15px 20px;
	text-align: center;
	margin: 20px 0 -20px;
}

.sub-page-link {
	text-align: right;
	font-size: 1.4rem;
}
.sub-page-link a,
.sub-page-link a:hover {
	color: #007DFF;
}
.sub-page-link .material-icons {
	margin-top: -2px;
	vertical-align: middle;
}


/**** Navbar start *****/
.navbar-right {
	float: right !important;
	margin: 0 !important;
}

.navbar-right .dropdown-toggle {
	position: relative;
	z-index: 1001;
	width: 50px;
	height: 39px;
	margin: 0;
	padding: 12px 15px;
	border: 0;
	border-radius: 0;
}

.navbar-right .dropdown-toggle:hover, .navbar-right .dropdown-toggle:focus {
	background: transparent;
}

.navbar-right .dropdown-toggle .icon-bar {
	position: absolute;
	left: 15px;
	width: 20px;
	height: 1px;
	margin: 0;
	background: #0082F9;
	transition: all .5s linear;
	-webkit-transition: all .5s linear;
	-ms-transition: all .5s linear;
}

.navbar-right .dropdown-toggle .icon-bar:first-child {
	top: 14px;
}

.navbar-right .dropdown-toggle .icon-bar:nth-child(2) {
	top: 19px;
}

.navbar-right .dropdown-toggle .icon-bar:nth-child(3) {
	top: 24px;
}

.navbar-right .dropdown-menu {
	right: 0 !important;
	left: auto !important;
	min-width: 250px;
	border: none;
	box-shadow: none;
}

.navbar-right .dropdown-menu .regular-deposit-status {
	color: #FFF;
	font-size: 1.2rem;
	margin-left: 5px;
	padding: 2px 8px;
	border-radius: 3px;
	vertical-align: 1px;
}

.navbar-right .dropdown-menu .regular-deposit-enabled {
	background-color: #007DFF;
}

.navbar-right .dropdown-menu .regular-deposit-disabled {
	background-color: #a8afba;
}

.navbar-right .open .dropdown-toggle .icon-bar:first-child {
	top: 20px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.navbar-right .open .dropdown-toggle .icon-bar:nth-child(2) {
	top: 20px;
	opacity: 0;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.navbar-right .open .dropdown-toggle .icon-bar:nth-child(3) {
	top: 20px;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.navbar-right .open .dropdown-menu {
	top: 0;
	margin: 40px 0 0;
}

.navbar-nav {
	font-family: 'HelveticaNeue-Light', 'Yu Gothic Medium', YuGothic, '游ゴシック Medium', 'ヒラギノ角ゴPro W3', 'Hiragino Kaku Gothic Pro', Meiryo, 'メイリオ', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: auto;
}

.navbar-nav > li {
	float: left !important;
}

.navbar-nav > li > a {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.navbar-nav .open .dropdown-menu {
	position: absolute!important;
	padding: 16px 0;
	border: 1px solid rgba(0, 0, 0, .05) !important;
	background-color: rgba(255, 255, 255, .95) !important;
	text-align: center;
}

.navbar-nav .open .dropdown-menu li {
	margin: 12px 0;
	font-size: 16px;
	text-align: left;
}

.navbar-default .navbar-nav .open .dropdown-menu li a {
	padding: 5px 50px;
	color: #007DFF;
}

.navbar-nav .open .dropdown-menu li a:hover {
	background: #F5F5F5;
	color: #065FE3;
	text-decoration: none;
}

.navbar-nav .open .dropdown-menu li.divider {
	width: 60%;
	margin: 16px auto;
}

.navbar-nav .open:before {
	content: ' ';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .1);
}

.nav-pc {
	height: 40px;
	display: none;
	color: #00263f;
	>li {
		font-size: 13px !important;
		.nav-label {
			font-size: 13px !important;
		}
	}
}

@media (min-width: 770px) {
	.navbar-nav.nav-pc {
		display: flex;
	}

	.navbar-nav {
		display: none;
	}
}

.nav-pc li {
	line-height: 40px;
	position: relative;
	cursor: pointer;
}

.nav-pc .dropdown {
	display: none;
	position: absolute;
	z-index: 2;
	list-style: none;
	padding: 10px 0;
	white-space: nowrap;
	background-color: #FFF;
	box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.25);
}

.nav-pc > li {
	margin: 0 5px !important;
}

.nav-pc > li:hover .dropdown {
	display: block;
}

.nav-pc .nav-label {
	padding: 0 10px;
}

.nav-pc .nav-label .material-icons {
	transform: translateY(6px);
}

.nav-pc .dropdown .material-icons {
	font-size: 18px;
	vertical-align: middle;
	margin-right: 5px;
}

.nav-pc > li > a {
	color: #00263f !important;
	padding: 10px 18px !important;
}

.nav-pc > li.active > a {
	border-bottom: 3px solid #007dff;
	border-top: 3px solid transparent;
	background-color: transparent!important;
	padding: 7px 18px !important;
}

.nav-pc > li > a:hover {
	background: rgba(0, 38, 63, .1) !important;
	text-decoration: none !important;
}

.nav-pc .dropdown a {
	color: #00263f !important;
	display: block;
	padding: 0px 20px;
}

.nav-pc .dropdown a:hover {
	background: rgba(0, 38, 63, .1);
	text-decoration: none !important;
}

.nav-pc .dropdown:before, .nav-pc .dropdown:after {
	content: " ";
	position: absolute;
	left: 0;
	width: 110px;
}

.nav-pc .dropdown:before {
	top: -40px;
	height: 36px;
	box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.25);
}

.nav-pc .dropdown:after {
	top: -4px;
	height: 6px;
	background: #fff;
	z-index: 3;
}

.nav-pc li.divider {
	width: 80%;
	height: 1px;
	margin: 8px auto;
	background-color: #e5e5e5;
}

.nav-pc .regular-deposit-status {
	background-color: #a8afba;
	color: #FFF;
	padding: 2px 5px;
	font-size: 10px;
	border-radius: 2px;
	vertical-align: top;
}

.nav-pc .regular-deposit-enabled {
	background-color: #007dff;
}
/**** Navbar end *****/

.section-tips {
  margin-top: 30px;
  padding: 30px 30px 30px 50px;
  background-color: #fafafa;
  color: #00263f;
}
.section-tips .text-o-asterisk {
  padding: 0;
  margin: 0;
  text-indent: -20px;
  line-height: 20px;
}
.section-tips .text-o-asterisk:before {
  content: "•";
  margin-right: 1.5rem;
  font-size: 10px;
  vertical-align: top;
}

.annotation {
	font-size: 1.0rem;
}

.annotation-description {
	font-size: 1.2rem;
	color: #999;
}

@media (min-width: 415px) {
	.show-sp {
		display: none;
	}
}

.default-cancel {
	background-color: #EEF5FF;
	margin-top: 48px;
	padding: 16px;
	border-radius: 4px;
}

.default-cancel .default-cancel-title{
	font-size: 1.6rem;
	line-height: 1;
	font-weight: bold;
	margin: 0 0 8px 8px;
	color: #00263F;
}

.default-cancel .default-cancel-list{
	line-height: 1.5;
	color: #616977;
	padding-left: 16px;
}

.default-cancel .default-cancel-list{
	font-size: 1.4rem;
}

.default-cancel .default-cancel-list .default-cancel-text ul{
	padding-left: 15px;
	line-height: 1.5;
}

.default-cancel .default-cancel-list > li{
	list-style-type: none;
}

.default-cancel .default-cancel-list > li::before{
	position: relative;
	left: -18px;
	top: 6px;
	height: 6px;
	content: '・';
	display: block;
	border-radius: 100%;
}

.box-todo-list .box-todo-unit .desc{
	text-align: left;
	font-size: 1.3rem;
	line-height: 1.5;
	height: 82px;
}

.box-todo .box-todo_item {
	padding: 10px 50px;
}

/* dpoints for top page */
.img-dpoints {
	width: 32px;
	height: 32px;
	margin-left: 20px;
	margin-right: 10px;
}

.link-dpoints {
	color: #065FE3!important;
	text-decoration-line: underline!important;
}

/* dpoints page */
.middle-title {
	font-size: 1.6rem;
	font-weight: bold;
}

.section-dpoints {
	width: 100%;
	max-width: 500px;
	margin: auto;
}

.box-o-dark-gray {
	background-color: #f6f6f6;
	border-radius: 4px;
	padding: 15px;
	margin: 16px 0;
}

.table-o-dpoints {
	width: 100%;
}
.table-o-dpoints tr th, .table-o-dpoints tr td {
	padding: 0 0 10px;
	color: #00263f;
	font-weight: 500;
	vertical-align: baseline;
}
.table-o-dpoints tr th {
	font-size: 1.4rem;
	text-align: left;
}
.table-o-dpoints tr td {
	font-size: 1.4rem;
	text-align: right;
}

.label-points {
	font-size: 2.0rem;
	font-weight: 300;
}

.box-todo_item.box-todo-introApp {
	margin-top: 48px;
	padding: 60px 30px 60px;
	.flex-introApp {
		display: flex;
		justify-content: space-around;
	}
	.inrL-introApp {
		text-align: left;
		.subTtl-introApp {
			font-size: 10px;
			color: #065FE3;
		}
		.ttl-introApp {
			font-size: 20px;
			font-weight: bold;
			margin: 0 0 24px;
		}
		.lead-introApp {
			font-size: 13px;
			line-height: 1.7;
			margin-bottom: 24px;
		}
	}
	.inrR-introApp {
		max-width: 224px;
		margin: 0 auto;
	}
	.img-introApp {
		img {
			width: 100%;
		}
	}
	@media screen and (min-width: 768px) {
		.flexPc-introApp {
			display: flex;
			justify-content: space-between;
		}
		.inrL-introApp {
			width: 300px;

		}
		.inrR-introApp {
			width: 112px;
		}
		.flex-introApp .img-introApp {
			width: 135px;
		}
	}
	@media screen and (max-width: 767px) {
		padding: 40px 30px 40px;
		.flex-introApp .img-introApp {
			min-width: 135px;
			width: 40%;
		}
		.inrR-introApp {
			margin-bottom: 16px;
		}
	}
}

.page-back-link {
	display: inline-block;
	font-size: 14px;
	line-height: 1;
	margin-bottom: 24px;
	padding-left: 16px;
	position: relative;

	&:before {
		background-image: url("/_/image/ico_page_back.png");
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 8px 13px;
		bottom: 0;
		content: '';
		display: inline-block;
		height: 13px;
		left: 0;
		position: absolute;
		top: 0;
		vertical-align: middle;
		width: 8px;
	}
}

@media screen and (min-width: 768px) {
	.spOnly {
		display: none !important;
	}
	.pcOnly {
		display: block !important;
	}
}
@media screen and (max-width: 767px) {
	.spOnly {
		display: block !important;
	}
	.pcOnly {
		display: none !important;
	}
}

.kantan-step-area {
	border: solid 15px #F5F5F5;
	padding: 20px;
	margin: 48px 0px 48px 0px;

	.guide {
		font-size: 18px;
		text-align: center;
		color: #065FE3;
		margin-bottom: 12px;
		font-weight: 700;
	}

	.explanation {
		font-size: 12px;
		text-align: center;
		margin-bottom: 15px;
	}

	.kantan-step {
		text-align: center;
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 20px;
	}

	.underLine {
		background: linear-gradient(transparent 75%, yellow 50%);
		filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.2));
	}

	.step-3 {
		font-size: 24px;
		color: #007DFF;
		margin: 0px 8px 0px 8px;
	}

	.attention {
		font-size: 10px;
		font-weight: 100;
		display: block;
		line-height: 10px;
	}

	.koutei-area{
		border: solid 1px #065FE3;
		border-radius: 15px;
		max-width: 320px;
		margin: 0 auto;
	}

	.koutei-area ol {
		font-size: 14px;
		font-weight: 700;
		list-style: none;
		padding-left: 35px;
	}

	.koutei-area ol li {
		background: 0 no-repeat;
		background-size: 15px 15px;
		margin: 13px 0;
		padding-left: 22px;
		background-position-y: 5px;
	}

	.triangle{
		width: 0;
		height: 0;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid #afafb0;
		margin: 5px 0px 0px 38px;
	}

	.koutei-area ol li:nth-child(1) {
		background-image: url(/_/image/ico_kantan_step_user_icon.png);
	}

	.koutei-area ol li:nth-child(2) {
		background-image: url(/_/image/ico_kantan_step_check_icon.png);
	}

	.koutei-area ol li:nth-child(3) {
		background-image: url(/_/image/ico_kantan_step_upload_icon.png);
	}
}

.subheading {
	font-size: 1.6rem;
	line-height: 1;
	font-weight: bold;
	margin-top: 48px;
	margin-bottom: 16px;
	color: #00263F;
	text-align: center;
}

.attention {
	background-color: #eef5ff;
	border-radius: 4px;
	padding: 16px;
	width: 100%;

	.attention-title {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 12px;
	}
	.attention-text {
		font-size: 12px;
		line-height: 24px;
		margin: 0;
	}
	.attention-list {
		list-style-type: none;
		margin: 0;
		padding: 0;

		.attention-item {
			font-size: 12px;
			line-height: 24px;
			padding-left: 1em;
			text-indent: -1em;
			padding-top: 12px;
			padding-bottom: 12px;

			&:not(:last-child) {
				margin-bottom: 4px;
			}

			&:before {
				content: '*';
				display: inline;
				margin: 0 .5rem 0 0;
			}
		}
	}
}

/* 3d secure redirect*/
.redirect-content {
	text-align: left;
	display: inline-block;
}

.redirect-button-area {
	margin-top: 40px;
}

.docomo-errorcode-message {
	background: #f6f6f6;
	padding: 14px;
	p {
		margin: 0;
	}
}