/* フォント */
p {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

p.note,
li.note{
  font-size: 12px;
  line-height: 1.5;
  color: #666;
}

/* リンク */
a {
  color: #007BFF;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: #23527c;
  text-decoration: underline;
}

/* リスト */
ul.list, ol.list {
  margin: 0;
  padding: 0 0 0 16px;
}

ul.list.-asterisk {
  list-style: "*";
}

ul.list.-disc {
  list-style: disc;
  li::marker {
    font-size: 0.8em;
  }
}

ul.list li,
ol.list li {
  padding-left: 8px;
  margin-bottom: 10px;
}

/* ユーティリティ */
.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
