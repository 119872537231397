$sizes: 8px, 16px, 24px, 32px, 40px;

@each $size in $sizes {
  .margin-#{$size} {
    margin: $size !important;
  }

  .padding-#{$size} {
    padding: $size !important;
  }

  .margin-top-#{$size} {
    margin-top: $size !important;
  }

  .padding-top-#{$size} {
    padding-top: $size !important;
  }

  .margin-bottom-#{$size} {
    margin-bottom: $size !important;
  }

  .padding-bottom-#{$size} {
    padding-bottom: $size !important;
  }

  .margin-left-#{$size} {
    margin-left: $size !important;
  }

  .padding-left-#{$size} {
    padding-left: $size !important;
  }

  .margin-right-#{$size} {
    margin-right: $size !important;
  }

  .padding-right-#{$size} {
    padding-right: $size !important;
  }

  .margin-horizontal-#{$size} {
    margin-left: $size !important;
    margin-right: $size !important;
  }

  .padding-horizontal-#{$size} {
    padding-left: $size !important;
    padding-right: $size !important;
  }

  .margin-vertical-#{$size} {
    margin-top: $size !important;
    margin-bottom: $size !important;
  }

  .padding-vertical-#{$size} {
    padding-top: $size !important;
    padding-bottom: $size !important;
  }

  @media screen and (max-width: 599px) {
    .margin-#{$size}-sp {
      margin: $size !important;
    }

    .padding-#{$size}-sp {
      padding: $size !important;
    }

    .margin-top-#{$size}-sp {
      margin-top: $size !important;
    }

    .padding-top-#{$size}-sp {
      padding-top: $size !important;
    }

    .margin-bottom-#{$size}-sp {
      margin-bottom: $size !important;
    }

    .padding-bottom-#{$size}-sp {
      padding-bottom: $size !important;
    }

    .margin-left-#{$size}-sp {
      margin-left: $size !important;
    }

    .padding-left-#{$size}-sp {
      padding-left: $size !important;
    }

    .margin-right-#{$size}-sp {
      margin-right: $size !important;
    }

    .padding-right-#{$size}-sp {
      padding-right: $size !important;
    }

    .margin-horizontal-#{$size}-sp {
      margin-left: $size !important;
      margin-right: $size !important;
    }

    .padding-horizontal-#{$size}-sp {
      padding-left: $size !important;
      padding-right: $size !important;
    }

    .margin-vertical-#{$size}-sp {
      margin-top: $size !important;
      margin-bottom: $size !important;
    }

    .padding-vertical-#{$size}-sp {
      padding-top: $size !important;
      padding-bottom: $size !important;
    }
  }
}

.margin-top-0 {
  margin-top: 0 !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.margin-horizontal-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.padding-horizontal-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.margin-vertical-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.padding-vertical-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}